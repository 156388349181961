import { Clipboard } from '@angular/cdk/clipboard';
import { Location } from '@angular/common';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import {
  BehaviorSubject,
  catchError,
  firstValueFrom,
  Observable,
  of,
  Subject,
  throwError,
} from 'rxjs';
import { GenericResponse } from 'src/app/models/shared/generic-response.model';
import { CommonForm } from 'src/app/models/user/common-form.model';
import {
  ACTIVITY_TYPE,
  API,
  API_DRAFT,
  API_EDS_QUERY,
  API_OTP,
  API_SWS,
  COMPLIANCE_ABBR,
  DATE_FORMATS,
  EXEMPTED_PARAMS,
  FC_2_ABBR,
  FC_3_ABBR,
  FC_4_ABBR,
  FC_FACTSHEET_ABBR,
  FC_FORM_NAMES,
  PROJECT_CATEGORY,
  PROPONENT_APPLICATION_ID,
  SECTOR_DETAIL,
  SIR_ABBR,
} from 'src/app/shared/app-constant';
import { environment } from 'src/environments/environment';
import { AppState } from '../state/app-state/app-reducer';
import { SharedActions } from '../state/shared-state/shared-action-types';
import {
  isDistrictLoaded,
  isStateLoaded,
  selectAllProposalByProposalId,
  selectDistrict,
  selectState,
} from '../state/shared-state/shared.selector';
import { ClearenceService } from './clearence.service';
import { DigitalSignatureService } from './digital-signature/digital-signature.service';
import { EdsQueryService } from './eds-query.service';
import { LoaderService } from './loader.service';
import { MessageService } from './message.service';
import { EnvionmentClearancePartBService } from './organisation/envionment-clearance-part-b.service';
import { FcBService } from './organisation/fc-form-b.service';
import { ForestClearancesService } from './organisation/forest-clearances.service';
import { ProponentApplicationService } from './organisation/proponent-applications.service';
import { SessionStorageService } from './session-storage.service';
import { UserService } from './user.service';
import { tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class SharedServiceService {
  private _loaderService!: LoaderService;
  existedProposalsById: any;

  hidePendencyFor: number[] = [43, 45, 8, 561317, 4530821];

  SIAProposalsAllowedStates: number[] = [
    17, 21, 5, 18, 30, 33, 4, 1, 6, 38, 16, 23, 14, 11, 3, 2, 7, 28, 22, 24, 12,
    9, 19, 27, 34, 32, 10, 8, 15, 35, 31, 37, 36, 13, 29,
  ];
  signCertificateResponse: any;
  isStateAvailable: boolean = false;
  isDistrictAvailable: boolean = false;
  constructor(
    private http: HttpClient,
    private location: Location,
    private ecBService: EnvionmentClearancePartBService,
    private fcAService: ForestClearancesService,
    private fcBService: FcBService,
    private userService: UserService,
    private clipboard: Clipboard,
    private messageService: MessageService,
    private translateService: TranslateService,
    private edsQueryService: EdsQueryService,
    private injector: Injector,
    private msgService: MessageService,
    private sessionStorageService: SessionStorageService,
    private proponentApplicationService: ProponentApplicationService,
    private clearenceService: ClearenceService,
    private digitalSignatureService: DigitalSignatureService,
    private store: Store<AppState>
  ) {
    this.store.pipe(select(isStateLoaded)).subscribe({
      next: (isStateLoaded) => (this.isStateAvailable = isStateLoaded),
    });
    this.store.pipe(select(isDistrictLoaded)).subscribe({
      next: (isDistrictLoaded) => (this.isDistrictAvailable = isDistrictLoaded),
    });
    this.store.pipe(select(selectAllProposalByProposalId)).subscribe({
      next: (response) =>
        (this.existedProposalsById = JSON.parse(JSON.stringify(response))),
    });
  }

  getStates(): Observable<any> {
    if (this.isStateAvailable) return this.store.pipe(select(selectState));
    this.store.dispatch(SharedActions.loadAllStates());

    return this.http.get<any>(`${environment.baseURL}${API.GET_STATE}`);
  }

  getDistrict(): Observable<any> {
    if (this.isDistrictAvailable)
      return this.store.pipe(select(selectDistrict));
    this.store.dispatch(SharedActions.loadAllDistrict());
    return this.http.get<any>(`${environment.baseURL}${API.GET_DISTRICT}`);
  }

  transformHtml = (value: any, isCertificate?: boolean, isString?: boolean) => {
    // value = this.sanitized.bypassSecurityTrustHtml(
    //   value
    //     ?.replaceAll(
    //       '<tr>',
    //       '<tr class="tr-border" style="border: 1px solid black;">'
    //     )
    //     ?.replaceAll(
    //       '<td ',
    //       '<td class="td-border" style="border: 1px solid black;"'
    //     )
    //     ?.replaceAll(
    //       '<td> ',
    //       '<td class="td-border" style="border: 1px solid black;">'
    //     )
    // );
    // if (isString) return value?.toString();
    return value;
  };

  commonDetailForm: CommonForm = new CommonForm();
  private commonDetail = new BehaviorSubject<CommonForm>(this.commonDetailForm);
  updatedCommonDetail = this.commonDetail.asObservable();
  enclosureSubject: Subject<boolean> = new Subject<boolean>();
  userSubject: Subject<any> = new Subject<any>();
  AirportSubject: Subject<boolean> = new Subject<boolean>();
  MiningSubject: Subject<boolean> = new Subject<boolean>();
  IrrigationHydel: Subject<boolean> = new Subject<boolean>();
  selectMultipleDropdownList$: Subject<any> = new Subject<any>();
  MiningMineralOil$: Subject<boolean> = new Subject<boolean>();
  getDataFromDraft: Subject<{ isCall: boolean; responseData: any }> =
    new Subject<{ isCall: boolean; responseData: any }>();
  saveDraftData: Subject<any> = new Subject<any>();
  eventsSubject: Subject<string> = new Subject<string>();
  proposalDataSubject: Subject<any> = new Subject<any>();
  submitCrzConditions: Subject<boolean> = new Subject<boolean>();

  fc2Abbr: string[] = FC_2_ABBR;
  fc3Abbr: string[] = FC_3_ABBR;
  fc4Abbr: string[] = FC_4_ABBR;
  sir: string[] = SIR_ABBR;
  complianceAbbr: string[] = COMPLIANCE_ABBR;
  factsheetAbbr: string[] = FC_FACTSHEET_ABBR;

  fcFormNames: string[] = FC_FORM_NAMES;

  public get loaderService(): LoaderService {
    if (!this._loaderService) {
      this._loaderService = this.injector.get(LoaderService);
    }
    return this._loaderService;
  }

  isLoading() {
    return this.loaderService.isLoading;
  }

  updateCommonDetail(detail: CommonForm) {
    this.commonDetail.next(detail);
  }

  //Openkm Start
  // getDocumentTypeByModuleID(moduleID: number, step: number) : Observable<GenericResponse>{
  // 	let param: HttpParams = new HttpParams();
  // 	param = param.append('Module Id', moduleID);
  // 	// param = param.append('Step', step);
  // 	var url = environment.baseURLWLC + API.GETDOCUMENT_BY_MODULEID;
  // 	return this.http.get<any>(url, { params: param });
  // }
  // publicDocument(data: string | Blob, obj: { documentId: any; displayName: any; keyword_details: { refDocumentId: number; keywords: any; }[]; validFrom: string; }) {
  // 	const header: any = this._global.getHeaderMultiPart();
  // 	let formParams = new FormData();
  // 	formParams.append('file', data);
  // 	formParams.append('refDocDetails', JSON.stringify(obj));
  // 	var url =environment.baseURLKMLUpload + API.ADD_DOCUMENT_DETAILS;
  //   return this.http.post(url, formParams, { headers: header });
  // }

  getCountryCode(): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_COUNTRY_CODE;
    return this.http
      .get<GenericResponse>(url)
      .pipe(catchError(this.handleError));
  }

  getProjectCategory(): Observable<any> {
    let url = environment.baseURLMis + API.GET_FC_PROJECT_CATEGORY;
    let param = new HttpParams().append('isactive', true);
    return this.http.get<any>(url, { params: param });
  }

  getExoticForm1Data(
    id: string,
    regId: string,
    process: string
  ): Observable<GenericResponse> {
    let url = `${environment.wlcBaseURL}parivesh/wlpas/getform1?id=${id}&regId=${regId}&process=${process}`;
    return this.http
      .get<GenericResponse>(url)
      .pipe(catchError(this.handleError));
  }

  getExoticForm1Pdf(
    id: string,
    regId: string,
    process: string
  ): Observable<GenericResponse> {
    let url = `${environment.wlcBaseURL}parivesh/wlpas/getform1download?id=${id}&regId=${regId}&process=${process}&conditions=test`;
    return this.http
      .get<GenericResponse>(url)
      .pipe(catchError(this.handleError));
  }

  getAdditionalInformation(ref_id: number): Observable<any> {
    let url =
      environment.baseURL +
      API.GET_ADDITIONAL_INFORMATION +
      '?ref_id=' +
      ref_id +
      '&is_special_document=' +
      false;
    // let param = new HttpParams();
    // param = param.append('ref_id', ref_id);
    // param = param.append('is_special_document', false);
    // console.log('PARAMS -> ', param);
    return this.http.post<any>(url, {});
  }

  downloadFile(
    uuid: string,
    refId: string,
    refType: string,
    docTypemappingId: string,
    version: any
  ) {
    let url = `${environment.baseURLKMLUpload}/okm/downloadDocument`;
    let param: HttpParams = new HttpParams();
    param = param.append('docTypemappingId', docTypemappingId);
    param = param.append('refId', refId);
    param = param.append('refType', refType);
    param = param.append('uuid', uuid);
    param = param.append('version', version);
    return this.http.get(url, { params: param, responseType: 'blob' });
  }

  getExoticForm1Doc(id: string): Observable<GenericResponse> {
    let url = `${environment.wlcBaseURL}parivesh/wlpas/getUploadedDoc?id=${id}`;
    return this.http
      .get<GenericResponse>(url)
      .pipe(catchError(this.handleError));
  }

  getDuplicateEmail(email: any, entType: string): Observable<GenericResponse> {
    let url = environment.baseURL + API.CHECK_DUPLICATE_EMAIL;
    const param = new HttpParams()
      .append('email', email.trim())
      .append('entityType', entType.trim());
    return this.http
      .get<GenericResponse>(url, { params: param })
      .pipe(catchError(this.handleError));
  }

  getStatePendancy(): Observable<GenericResponse> {
    let param: HttpParams = new HttpParams().append('days', 150);
    let url = environment.baseURLProcessEngine + API.STATE_REPORT;
    return this.http.post<any>(url, {}, { params: param });
  }

  getReportingReason(): Observable<GenericResponse> {
    let url = environment.wlcBaseURL + 'parivesh/wlpa-Common/reporting-reasons';
    return this.http
      .get<GenericResponse>(url)
      .pipe(catchError(this.handleError));
  }

  getAcqutionPurpose(): Observable<GenericResponse> {
    let url = environment.wlcBaseURL + 'parivesh/wlpa-Common/acquisition-modes';
    return this.http
      .get<GenericResponse>(url)
      .pipe(catchError(this.handleError));
  }
  getScienceName(): Observable<GenericResponse> {
    let url = environment.wlcBaseURL + 'parivesh/wlpa-Common/master-exotic';
    return this.http
      .get<GenericResponse>(url)
      .pipe(catchError(this.handleError));
  }

  getModeOfAcquisition(): Observable<GenericResponse> {
    let url =
      environment.wlcBaseURL +
      'parivesh/wlpa-Common/species-aquisition-purpose';
    return this.http
      .get<GenericResponse>(url)
      .pipe(catchError(this.handleError));
  }

  getStateDepartment(code: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_STATEDEPARTMENT;
    return this.http
      .post<GenericResponse>(url, {}, { params: { code } })
      .pipe(catchError(this.handleError));
  }

  uploadDocument(proposalNo: any, body: any): Observable<GenericResponse> {
    const formData: FormData = new FormData();
    formData.append('multipartFile', body);

    let param: HttpParams = new HttpParams().append('proposalNo', proposalNo);
    let url = environment.baseURLAgendaMom + API.UPLOAD_DOC;
    return this.http.post<any>(url, formData, { params: param });
  }
  migrationDataSave(body: any): Observable<GenericResponse> {
    let url = environment.baseUrlLegacy + API.MIGRATION_DATA_SAVE;
    return this.http.post<any>(url, body);
  }

  saveImportantDocument(): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_IMPORTANT_DOCUMENT;
    return this.http
      .post<GenericResponse>(url, {})
      .pipe(catchError(this.handleError));
  }

  getDistrictsByStateCode(stateCode: number): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_DISTRICT_BY_CODE;
    let param = { state_code: stateCode };
    return this.http.get<GenericResponse>(url, { params: param });
  }

  getIroStates(): Observable<GenericResponse> {
    return this.http
      .post<GenericResponse>(environment.baseURL + API.GET_IRO_STATE, {})
      .pipe(catchError(this.handleError));
  }

  getSubDistrict(code: number): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_SUB_DISTRICT;
    return this.http
      .post<GenericResponse>(
        url,
        {},
        {
          params: { district_code: code },
        }
      )
      .pipe(catchError(this.handleError));
  }

  getVillage(code: number): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_VILLAGE;
    return this.http
      .post<GenericResponse>(
        url,
        {},
        {
          params: { sub_district_code: code },
        }
      )
      .pipe(catchError(this.handleError));
  }

  getVillageBySubDistrictCode(body: number[]): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_VILLAGES;
    return this.http
      .post<GenericResponse>(url, body)
      .pipe(catchError(this.handleError));
  }

  getRegistrationType(): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_REGISTRATION_TYPE;
    return this.http
      .get<GenericResponse>(url)
      .pipe(catchError(this.handleError));
  }

  getEntityType(): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_ENTITY_TYPE;
    return this.http
      .get<GenericResponse>(url)
      .pipe(catchError(this.handleError));
  }

  getActivation(token: string): Observable<GenericResponse> {
    let url = environment.baseURL + `${API.VERIFY_EMAIL}?token=${token}`;
    return this.http.get<GenericResponse>(url);
  }

  private handleError(err: HttpErrorResponse) {
    let errMsg: string = '';
    if (err.error instanceof Error) {
      errMsg = err.error.message;
    } else if (typeof err.error === 'string') {
      errMsg = 'A connection to back end can not be established.';
    } else {
      errMsg = err.error.message;
    }
    return throwError(errMsg);
  }

  getCafDetailsByProposalNo(
    proposal_no: string,
    searchBy?: string
  ): Observable<any> {
    if (this.existedProposalsById[proposal_no])
      return of({ data: this.existedProposalsById[proposal_no] });
    let param;
    if (searchBy == 'file_no') {
      param = { file_no: proposal_no };
    } else {
      param = { proposal_no };
    }
    return this.http
      .post<GenericResponse>(
        environment.baseURL + API.GET_CAF_DETAIL_BY_PROPOSAL_NO,
        {},
        { params: param }
      )
      .pipe(
        tap((response) =>
          this.store.dispatch(
            SharedActions.addProposalByProposalIdNumber({
              proposal: response?.data,
            })
          )
        )
      );
  }

  getApplicationName(clearence: string): Observable<GenericResponse> {
    let url = environment.baseURL + API.APPLICATION_NAME;
    let param = new HttpParams().append('Id', clearence);
    return this.http.post<any>(url, {}, { params: param });
  }

  getProposalDocument(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_PROPOSAL_DOCUMENT;
    let param = new HttpParams().append('id', id);
    return this.http.post<any>(url, {}, { params: param });
  }

  saveDraft(body: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API_DRAFT.SAVE_DRAFT,
      body
    );
  }

  getFormDataForCompare(formId: any, applicationId: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API_DRAFT.GET_FORM_DATA_FOR_COMPARE,
      {},
      { params: { formId, applicationId } }
    );
  }

  getFormDataForCompareWithStep(formId: any, applicationId: any, stepId: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API_DRAFT.GET_FORM_DATA_FOR_COMPARE_WITH_STEP,
      {},
      { params: { formId, applicationId, stepId } }
    );
  }

  getCurrentVersion(param: {
    applicationId: any;
    cafID: string;
    formId: string;
  }) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API_DRAFT.GET_CURRENT_VERSION,
      {},
      { params: param }
    );
  }

  getAllVersion(param: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API_DRAFT.GET_ALL_VERSION,
      {},
      { params: param }
    );
  }

  sendOtp(mobile: number, email: any, messageType?: any) {
    return this.http.post<GenericResponse>(
      environment.baseURLEngine + API_OTP.SEND_OTP,
      {},
      {
        params: {
          mobile,
          email,
          type: messageType || 'CHAIRMAN_OTP',
        },
      }
    );
  }

  updateUserMobile(mobile: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API_OTP.UPDATE_USER_MOBILE,
      {
        mobile,
      }
    );
  }

  verifyOtp(mobile: number, otp: number) {
    return this.http.post<GenericResponse>(
      environment.baseURLEngine + API_OTP.VERIFY_OTP,
      {},
      { params: { mobile, otp } }
    );
  }

  getSectorDetails(ecId: any) {
    return new Promise<string>((resolve) => {
      this.ecBService.getFormDetails(ecId).subscribe({
        next: (response) => {
          let sectorEntity: any[] = [];
          response.data.environmentClearanceProjectActivityDetails.forEach(
            (item: any, index: number) => {
              if (item?.activity_type === ACTIVITY_TYPE.MAJOR) {
                sectorEntity =
                  response.data.environmentClearanceProjectActivityDetails[
                    index
                  ].activities.ecSectorFormDetails;
                if (sectorEntity && sectorEntity.length) {
                  switch (sectorEntity[0].form) {
                    case SECTOR_DETAIL.AIRPORT:
                      {
                        resolve('/airport-proposals');
                      }
                      break;

                    case SECTOR_DETAIL.CONSTRUCTION:
                      {
                        resolve('/construction-details');
                      }
                      break;

                    case SECTOR_DETAIL.MINING:
                      {
                        resolve('/mining-proposals');
                      }
                      break;

                    case SECTOR_DETAIL.INDUSTRY:
                      {
                        resolve('/industry-proposals');
                      }
                      break;

                    case SECTOR_DETAIL.TDSF:
                      {
                        resolve('/tsdf-proposals');
                      }
                      break;

                    case SECTOR_DETAIL.CBWTF:
                      {
                        resolve('/cbwtf-proposals');
                      }
                      break;

                    case SECTOR_DETAIL.CEPT:
                      {
                        resolve('/cept-proposals');
                      }
                      break;

                    case SECTOR_DETAIL.CMSWMF:
                      {
                        resolve('/cmswmf-proposals');
                      }
                      break;

                    case SECTOR_DETAIL.BUILDING:
                      {
                        resolve('/building-proposals');
                      }
                      break;

                    case SECTOR_DETAIL.RIVER:
                      {
                        resolve('/river-valley-proposals');
                      }
                      break;
                  }
                } else {
                  resolve('/enclosures');
                }
              }
            }
          );
        },
      });
    });
  }

  getFcSectorDetails(fcId: any) {
    return new Promise<string>((resolve) => {
      this.fcAService.getFcDetails(fcId).subscribe({
        next: (response: any) => {
          let projectCategory = response.data.project_activity_id;
          switch (projectCategory) {
            case PROJECT_CATEGORY.AIRPORT:
              {
                resolve('/enclosures');
              }
              break;

            case PROJECT_CATEGORY.MINING:
              {
                resolve('/enclosures');
              }
              break;

            case PROJECT_CATEGORY.IRRIGATION:
              {
                resolve('/enclosures');
              }
              break;

            case PROJECT_CATEGORY.HYDEL:
              {
                resolve('/enclosures');
              }
              break;
            case PROJECT_CATEGORY.DRINKING_WATER:
              {
                resolve('/enclosures');
              }
              break;
            case PROJECT_CATEGORY.MINING_MINERAL_OIL:
              {
                resolve('/enclosures');
              }
              break;
            default: {
              resolve('/additional-information');
            }
          }
        },
      });
    });
  }

  getLoggerData() {
    return new Promise<any>((resolve) => {
      this.userService.getLoggedInUserAPI().subscribe({
        next: (response: any) => {
          resolve(response);
        },
        error: () => resolve(false),
      });
    });
  }

  getFcBSectorDetails(fcId: any) {
    return new Promise<string>((resolve) => {
      this.fcBService.getFcFormbProjectDetails(fcId, 5).subscribe({
        next: (response: any) => {
          let projectCategory = response.data?.project_category_code;
          switch (projectCategory) {
            case PROJECT_CATEGORY.MINING:
              {
                resolve('/category-specific-details');
              }
              break;
            default: {
              resolve('/additional-information');
            }
          }
        },
      });
    });
  }

  getTextWithoutHtml(textWithHtml: string) {
    let div: HTMLDivElement = document.createElement('div');
    div.innerHTML = textWithHtml;
    return div.textContent || div.innerText || '';
  }

  copyToClipBoard(textWithHtml: string) {
    let div: HTMLDivElement = document.createElement('div');
    div.innerHTML = textWithHtml;

    this.clipboard.copy(div.textContent || div.innerText || '');
    this.messageService.showSuccess(
      this.translateService.instant('generic.copy_to_clipboard')
    );
  }

  back() {
    if (window.history.length == 1) {
      window.close();
    } else {
      this.location.back();
    }
  }

  getOldProposalBySession() {
    return sessionStorage.getItem('is_for_old_proposal');
  }

  getObjectDifference = (a: any, b: any) => {
    let diff = this.isArray(a) ? [] : {};
    this.compare(a, b, diff);
    return diff;
  };

  compare = (a: any, b: any, node: any) => {
    for (let prop in a) {
      if (typeof b[prop] == 'undefined') {
        this.addNode(prop, 'REMOVED', node);
      } else if (JSON.stringify(a[prop]) != JSON.stringify(b[prop])) {
        if (typeof b[prop] != 'object' || b[prop] == null) {
          this.addNode(prop, b[prop], node);
        } else {
          if (this.isArray(b[prop])) {
            this.addNode(prop, [], node);
            this.compare(a[prop], b[prop], node[prop]);
          } else {
            this.addNode(prop, {}, node);
            this.compare(a[prop], b[prop], node[prop]);
          }
        }
      }
    }
  };

  addNode = (prop: any, value: any, parent: any) => {
    parent[prop] = value;
  };

  isArray = (obj: any) => {
    return Object.prototype.toString.call(obj) === 'Array';
  };

  getIPAddress() {
    return this.http.get<any>('http://api.ipify.org/?format=json');
  }

  getAllSectors(): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_LIST_OF_SECTOR;
    return this.http.get<GenericResponse>(url);
  }

  //crz
  getProposalHistory(applicationId: any): Observable<any> {
    // let params = new HttpParams().append('application_id', applicationId);
    var url = `${environment.baseURLProcessEngine}/process/getProcessHis?application_id=${applicationId}`;
    return this.http.post<any>(url, {});
  }

  getCafDetailsByProposalId(proposalId: string): Observable<any> {
    var url = `${environment.baseURL}/proponentApplicant/getCafDataByProposalNo?proposal_id=${proposalId}`;
    // let param = new HttpParams().append('proposal_id', proposalId);
    return this.http.post<any>(url, {});
  }

  getFcApprovalDates(proposalId: string): Observable<any> {
    var url = `${environment.baseURL}/proponentApplicant/getFcApprovalDates?proposalNo=${proposalId}`;
    // let param = new HttpParams().append('proposal_id', proposalId);
    return this.http.post<any>(url, {});
  }

  getParentApplicationByProposalId(proposalId: string): Observable<any> {
    // var url = `${environment.baseURL}/proponentApplicant/getCafDataByProposalNo?proposal_id=${proposalId}`;
    let url = environment.baseURL + API.GET_PARENT_APPLICATON_BY_ID;
    // let param = new HttpParams().append('proposal_id', proposalId);
    return this.http.post<any>(url, {}, { params: { proposalId } });
  }

  saveHistoryWise(body: any): Observable<any> {
    var url = `${environment.baseURLProcessEngine}/process/getHistoryWise`;
    return this.http.post(url, body);
  }

  updateApplicationStatusForEDS(
    app_history_id: any,
    action: string,
    app_id: any
  ): Observable<any> {
    var url = `${environment.baseURL}/crz/updateApplicationStatusForEDS?application_id=${app_id}&app_history_id=${app_history_id}&status=${action}`;
    return this.http.post<any>(url, {});
  }

  raiseEds(body: any, clearance_id: any) {
    var url = `${environment.baseURL}/edsV2/saveEDS?clearance_id=${clearance_id}`;
    return this.http.post(url, body);
  }

  process(body: any): Observable<any> {
    var url = `${environment.baseURLProcessEngine}/process/addHistory`;
    return this.http.post(url, body);
  }

  getEDSQueriesForCRZ(applicationId: any): Observable<any> {
    var url = `${environment.baseURL}/edsV2/getQueries?application_id=${applicationId}`;
    return this.http.post(url, {});
  }

  submitEdsQueries(body: any, clearance_id: string) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API_EDS_QUERY.SUBMIT_EDS_QUERIES,
      body,
      { params: { clearance_id } }
    );
  }

  getProposalsForNodalFC(): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_NODAL_PROPOSAL_FC;
    return this.http.post<GenericResponse>(url, {});
  }

  getProposalsForNodal(filterData?: any): Observable<GenericResponse> {
    let url = environment.baseURLProcessEngine + API.GET_NODAL_PROPOSAL;
    let param;
    if (filterData == true || filterData == 'true') {
      param = new HttpParams().append('filter', true);
    }
    return this.http.post<GenericResponse>(url, {}, { params: param });
  }

  getDfaHistory(proposal_id: any, clearenceId: any, type: any) {
    return this.http.get<GenericResponse>(
      environment.baseURL + API.GET_DFA_VERSIONS,
      {
        params: {
          proposal_id,
          clearenceId,
          type,
        },
      }
    );
  }

  viewSpecificCertificate(certId: any, clearenceId: any, type: any) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.GET_DFA_SPECIFIC_CERTIFICATE,
      {},
      {
        params: {
          certId,
          clearenceId,
          type,
        },
      }
    );
  }

  getFcProposals(response: any, historyData: any[], proposal?: any) {
    return new Promise<any[]>((resolve) => {
      let divisionData: any[] = [];
      let data = [...response?.data];
      data?.forEach((outerItem: any, index: number) => {
        outerItem?.historyWiseWithApplications?.forEach((innerItem: any) => {
          if (this.fc2Abbr?.includes(innerItem?.abbr)) {
            historyData.push({
              ...this.getData(outerItem, innerItem),
              name: 'Part II',
              order: 2,
            });

            if (!divisionData.length) {
              divisionData.push({
                ...this.getData(outerItem, innerItem),
                name: 'Part II',
                order: 2,
                isDivisionData: true,
              });
            } else {
              if (
                divisionData.some(
                  (item: any) =>
                    item.office_id == innerItem.office_id &&
                    item.office_name == innerItem.office_name
                )
              ) {
                divisionData.forEach((item: any, index: number) => {
                  if (
                    new Date(item.date_of_filling).getTime() -
                      new Date(innerItem.date_of_filling).getTime() >
                      0 &&
                    item.office_id == innerItem.office_id &&
                    item.office_name == innerItem.office_name
                  ) {
                    divisionData.splice(index, 1, {
                      ...this.getData(outerItem, item),
                      name: 'Part II',
                      order: 2,
                      isDivisionData: true,
                    });
                  } else if (
                    item.office_id == innerItem.office_id &&
                    item.office_name == innerItem.office_name
                  ) {
                    divisionData.splice(index, 1, {
                      ...this.getData(outerItem, innerItem),
                      name: 'Part II',
                      order: 2,
                      isDivisionData: true,
                    });
                  }
                });
              } else {
                divisionData.push({
                  ...this.getData(outerItem, innerItem),
                  name: 'Part II',
                  order: 2,
                  isDivisionData: true,
                });
              }
            }
          } else if (this.fc3Abbr?.includes(innerItem?.abbr)) {
            historyData.push({
              ...this.getData(outerItem, innerItem),
              name: 'Part III',
              order: 3,
            });
          } else if (this.fc4Abbr?.includes(innerItem?.abbr)) {
            historyData.push({
              ...this.getData(outerItem, innerItem),
              name: 'Part IV',
              order: 4,
            });
          } else if (this.sir?.includes(innerItem?.abbr)) {
            historyData.push({
              ...this.getData(outerItem, innerItem),
              name: 'SIR',
              order: 5,
            });
          } else if (this.factsheetAbbr?.includes(innerItem?.abbr)) {
            historyData.push({
              ...this.getData(outerItem, innerItem),
              name: 'Factsheet',
              order: 7,
            });
          } else if (this.complianceAbbr?.includes(innerItem?.abbr)) {
            historyData.push({
              ...this.getData(outerItem, innerItem),
              name: 'Compliance',
              order: 8,
            });
          }
        });

        historyData.push({
          name: 'Stipulated CA',
          abbr: 'STIPULATED_CA',
          proposal_no: outerItem?.proposal_no,
          clearence:290,
          order: 6,
        });

        if (data?.length == index + 1) {
          this.sortData(historyData);
          let result = historyData.reduce(
            (accumulator: any, currentValue: any) => {
              accumulator[currentValue.name] =
                accumulator[currentValue.name] || [];
              accumulator[currentValue.name].push(currentValue);
              return accumulator;
            },
            Object.create(null)
          );

          let final: any[] = [];

          for (let item in result) {
            final.push({
              name: item,
              items: result[item],
            });
          }

          historyData = [];

          final.forEach((item: any, index: number) => {
            if (item?.items?.length) historyData[index] = item?.items[0];
          });

          this.fcFormNames.forEach((item: any, index: number) => {
            if (item != historyData[index]?.name) {
              historyData.splice(index, 0, { name: item });
            }
          });
          let edsQueryTabExists = historyData.find(
            (item: any) => item?.name == 'Eds Query History'
          );
          if (!edsQueryTabExists) {
            historyData.push({
              name: 'Eds Query History',
              proposal_no: 'EDS',
              filled_by: null,
              last_submission_date: null,
              order: 9,
            });
          }

          if (divisionData?.length > 0) {
            historyData.splice(1, 1, ...divisionData);
          }

          this.sortData(historyData);

          resolve(historyData);
        }
      });
    });
  }

  getEdsQueryData(proposal: any) {
    return new Promise<boolean>((resolve) => {
      this.edsQueryService
        .getQueries(proposal?.proponentApplications?.id)
        .subscribe({
          next: (response) => {
            resolve(!!response?.data?.length);
          },
          error: () => resolve(false),
        });
    });
  }

  sortData(historyData: any) {
    return historyData.sort((a: any, b: any) => a.order - b.order);
  }

  getData(outerItem: any, innerItem: any) {
    return {
      ...innerItem,
      proposal_no: outerItem?.proposal_no,
      filled_by: innerItem?.role_name,
      last_submission_date: this.getDisplayDateFormat(
        innerItem?.date_of_filling
      ),
    };
  }

  getDisplayDateFormat(date: string | Date): string {
    return date ? moment(date).format(DATE_FORMATS.DISPLAY_FORMAT) : '';
  }

  saveRecommendedArea(body: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_RECOMMENDED_AREA;
    return this.http.post<GenericResponse>(url, body);
  }

  getRecommendedArea(fcId: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_RECOMMENDED_AREA;
    let param = new HttpParams().append('fc_id', fcId);
    return this.http.post<GenericResponse>(url, {}, { params: param });
  }

  saveDocumentDetails(body: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_PROPOSAL_DOCUMENT;
    return this.http.post<GenericResponse>(url, body);
  }

  deleteDocumentDetails(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_PROPOSAL_DOCUMENT;
    return this.http.post<GenericResponse>(url, {}, { params: { Id: id } });
  }

  exportPDF(string: any): any {
    let url = 'http://stgdev.parivesh.nic.in/pdfutil/pdf/generateHtmlPdf';
    let param = new HttpParams().append('title', 'AAAAAAAAAaa');
    return this.http.post(url, string, { params: param, responseType: 'blob' });
  }

  addClassToTable(element: any, classValue: string) {
    let ele = document.createElement('div');
    ele.innerHTML = element;
    let tables: any = ele.getElementsByTagName('table');
    for (var i = 0; i < tables.length; i++) {
      tables[i].className = tables[i].className + classValue;
    }
    return ele.outerHTML;
  }

  updateSws(req_id: any, application_id: any, sws_id: any) {
    return this.http.post(
      environment.baseURL + API_SWS.UPDATE_APPLICATION,
      {},
      {
        params: { req_id, application_id, sws_id },
      }
    );
  }

  processHistoryFallBack(
    proposalNo: any,
    status: string
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.PROCESS_HISTORY_FALL_BACK;
    return this.http.post<GenericResponse>(
      url,
      {},
      { params: { proposalNo, status } }
    );
  }

  setProponentApplicationId(value: any) {
    this.sessionStorageService.set(PROPONENT_APPLICATION_ID, value);
  }

  checkSeiaaStatus(state: any) {
    return this.http.post(
      environment.baseURL + API.CHECK_SIEAA_STATUS,
      {},
      {
        params: { state },
      }
    );
  }

  processHistoryFall(proposalNo: any, status: string) {
    this.processHistoryFallBack(proposalNo, status).subscribe({
      next: (res: any) => {
        this.msgService.showError(
          this.translateService.instant('generic.fall_back')
        );
      },
      error: (err) => {
        this.msgService.showError(
          this.translateService.instant('generic.error')
        );
      },
    });
  }

  async esignFormDocument(refId: any, refType: any, file: any) {
    try {
      const data: any = await firstValueFrom(
        this.proponentApplicationService.updateFormDocument(
          refId,
          refType,
          file
        )
      );

      const url = `${environment.frontendURL}#/aadhar-sign/sign?file_path=${data.data}&ref_id=${refId}&ref_type=${refType}&module=PP_FORM&redirect_url=${window.location.href}`;

      window.location.href = url;
    } catch (error) {
      console.log('error', error);
    }
  }

  downloadDocumentByRef(path: any) {
    this.clearenceService.downloadDocumentByPath(path).subscribe({
      next: (response) => {
        let url = window.URL.createObjectURL(response);
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = path;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      },
      error: (err) => {
        console.log('error', err);
        this.messageService.showError(
          'Something went wrong while downloading the file for e-sign.'
        );
      },
    });
  }

  async identifyExemptedScenario(
    area: any,
    isLinear: any,
    exemptedCategory: any,
    isViolation: any
  ) {
    try {
      if (
        isViolation.toLowerCase() == 'no' &&
        (exemptedCategory == EXEMPTED_PARAMS.EXEMPTED_CATEGORY.INFRA ||
          exemptedCategory ==
            EXEMPTED_PARAMS.EXEMPTED_CATEGORY.SECURITY_RELATED_INFRA)
      ) {
        return EXEMPTED_PARAMS.SCENARIOS.DEF_SCENARIO;
      } else if (
        (exemptedCategory ==
          EXEMPTED_PARAMS.EXEMPTED_CATEGORY.DEFENCE_RELATED_INFRA_LWE_PUBLIC ||
          exemptedCategory ==
            EXEMPTED_PARAMS.EXEMPTED_CATEGORY
              .DEFENCE_RELATED_INFRA_LWE_DEFENCE) &&
        area <= 5 &&
        isViolation?.toLowerCase() == 'no'
      ) {
        return EXEMPTED_PARAMS.SCENARIOS.DEF_SCENARIO;
      } else if (
        exemptedCategory ==
          EXEMPTED_PARAMS.EXEMPTED_CATEGORY.ROAL_RAIL_AMINITIES &&
        area <= 0.1 &&
        isLinear?.toLowerCase() == 'yes' &&
        isViolation?.toLowerCase() == 'no'
      ) {
        return EXEMPTED_PARAMS.SCENARIOS.SCENARIO_1_C;
      }
      return '';
    } catch (error) {
      console.log(error);
      return '';
    }
  }

  async eSignNoteWithDSC() {
    try {
      let response: any = '';
      // Check if dsc is live
      await this.digitalSignatureService.checkDigitalSignatureIsLive();

      // Check if dsc is already registered in the system
      const { body } = await firstValueFrom(
        this.digitalSignatureService.getUserCertificateList()
      );

      // if (isEmpty(body?.data)) {
      //Get list of certificates
      const certificateList = await firstValueFrom(
        this.digitalSignatureService.checkCertificateList()
      );

      const digitalSignerCeriticateList = await firstValueFrom(
        this.digitalSignatureService.getDigitalSignerCertificateList({
          transactionId: null,
          serialNumber: null,
          filePath: null,
          xmlResponse: certificateList,
        })
      );

      const digitalSignerCeriticate = digitalSignerCeriticateList[0];

      // Register certificate
      const registeredCertificate = await firstValueFrom(
        this.digitalSignatureService.registerDigitalSignature({
          transactionId: null,
          serialNumber: digitalSignerCeriticate?.serialNumber,
          filePath: null,
          xmlResponse: certificateList,
        })
      );

      if (registeredCertificate.status == '1') {
        // Sign certificate
        await this.getSignedCertificate(registeredCertificate);

        // Verify the registered certificate
        const verifiedCertificate = await firstValueFrom(
          this.digitalSignatureService.verifyRegister({
            transactionId: registeredCertificate?.transactionId,
            serialNumber: null,
            filePath: null,
            xmlResponse: this.signCertificateResponse,
          })
        );

        if (verifiedCertificate?.status == '1') {
          this.messageService.showSuccess(verifiedCertificate?.msg);
          const { body } = await firstValueFrom(
            this.digitalSignatureService.getUserCertificateList()
          );

          response = body?.data?.find((item: any) => item.active == true);
          return response;
        } else {
          throw verifiedCertificate;
        }
      } else {
        throw registeredCertificate;
      }
      // } else {
      //   //Get list of certificates
      //   const certificateList = await firstValueFrom(
      //     this.digitalSignatureService.checkCertificateList()
      //   );

      //   response = body?.data?.find((item: any) => item.active == true);
      //   return response;
      // }
    } catch (error) {
      this.msgService.showError(
        this.translateService.instant(
          'DSC Sign Failed. Please try after sometime. Make sure the DSC device is connected properly.'
        )
      );
      console.log(error);
      return '';
    }
  }

  getSignedCertificate(registeredCertificate: any) {
    return new Promise<any>((resolve) => {
      this.digitalSignatureService
        .signerService(registeredCertificate.xmlResponse)
        .subscribe({
          next: (res) => {
            this.signCertificateResponse = res;
            resolve(res);
          },
          error: (err) => resolve(err),
        });
    });
  }

  getselectedrole(role: any) {
    let roleName = ['Project Proponent', 'Employee', 'Consultant'];
    return !roleName.includes(role);
  }

  async identifySurveyExemptedScenario(
    isSurveyingProposal: any,
    noOfTreesFelling: any,
    noOfBoreholes: any,
    boreholesDiameter: any,
    noOfShortBoreholes: any,
    shortBoreholesDiameter: any
  ) {
    try {
      if (
        isSurveyingProposal?.toLowerCase() == 'yes' &&
        noOfTreesFelling <= 100 &&
        noOfBoreholes <= 25 &&
        boreholesDiameter <= 4 &&
        noOfShortBoreholes <= 80 &&
        shortBoreholesDiameter <= 6.5
      ) {
        return EXEMPTED_PARAMS.SCENARIOS.SCENARIO_1_E;
      } else if (
        isSurveyingProposal?.toLowerCase() == 'yes' && ((noOfBoreholes <= 25 && boreholesDiameter <= 4) || (noOfShortBoreholes <= 80 && shortBoreholesDiameter <= 6.5))
      ) {
        return EXEMPTED_PARAMS.SCENARIOS.SCENARIO_1_A;
      }
      return '';
    } catch (error) {
      console.log(error);
      return '';
    }
  }

  htmlCodeCleaner(html: any, isCertificate?: boolean) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(html, 'text/html');

    let body = doc.querySelectorAll('body');
    body.forEach(function (b, i) {
      b.innerHTML = b.innerHTML.replace(/&nbsp;/g, ' ');
      b.setAttribute('style', 'word-break: break-all !important;');
    });

    if (!isCertificate) {
      let images = doc.getElementsByTagName('img');
      let l = images.length;
      for (let i = 0; i < l; i++) {
        images[0].parentNode!.removeChild(images[0]);
      }
    }

    let temp_node = parser.parseFromString(html, 'text/html');
    let editor = doc.querySelectorAll('app-quill-editor');
    editor.forEach(function (g, i) {
      g.innerHTML = g.innerHTML
        .replace(/[^\x00-\x7F]/g, '')
        .replace(/\s+/g, '')
        .replace(/(?! )\s/g, '')
        .replace(/&nbsp;/g, '')
        .trim();
      let all_div = g.querySelectorAll('div');
      all_div.forEach(function (e, i) {
        e.setAttribute(
          'style',
          e.getAttribute('style') + 'word-break: break-all !important;'
        );
        e.innerHTML = e.innerHTML.trim();
      });
      let all_p = g.querySelectorAll('p');
      all_p.forEach(function (e, i) {
        e.setAttribute(
          'style',
          e.getAttribute('style') + 'word-break: break-all !important;'
        );
        e.innerHTML = e.innerHTML.trim();
      });

      let all_ul = g.querySelectorAll('ul');
      all_ul.forEach(function (e, i) {
        e.setAttribute('style', 'word-break: break-all !important;');
        e.innerHTML = e.innerHTML.trim();
      });
      let all_li = g.querySelectorAll('li');
      all_li.forEach(function (e, i) {
        e.setAttribute('style', 'word-break: break-all !important;');
        e.innerHTML = e.innerHTML.trim();
      });
      let editorDiv = document.createElement('div');
      editorDiv.setAttribute('class', 'app-quill-editor');
      editorDiv.innerHTML = g.innerHTML
        .replace(/[^\x00-\x7F]/g, '')
        .replace(/\s+/g, '')
        .replace(/(?! )\s/g, '')
        .replace(/&nbsp;/g, '')
        .trim();
      let val = g.getAttribute('style');
      editorDiv.setAttribute(
        'style',
        (val == null ? '' : val) +
          'width:100% !important;word-break: break-all !important;'
      );
      var parent = g.parentNode;
      if (parent != null) {
        parent.replaceChild(editorDiv, g);
      }
    });

    let all_figure = doc.querySelectorAll('figure');
    all_figure.forEach((f, i) => {
      this.addBorderToTables(doc, isCertificate);

      let newNode = document.createElement('div');
      newNode.innerHTML = f.innerHTML;
      let val = f.getAttribute('style');
      newNode.setAttribute('style', val == null ? '' : val);
      let parent = f.parentNode;
      if (parent != null) {
        parent.replaceChild(newNode, f);
      }
    });

    if (!all_figure?.length) this.addBorderToTables(doc, isCertificate);
    // let body_element = doc.getElementsByTagName('body')[0];
    // return body_element.innerHTML; //doc.documentElement.innerHTML;
    let str: any = doc.documentElement.innerHTML.toString();
    return str
      ?.replaceAll('<p style="margin:0px"> </p>', '<br>')
      ?.replaceAll('<p> </p>', '<br>')
      ?.replaceAll('<p>&nbsp;</p>', '<br>')
      ?.replaceAll('&lt;', '<')
      ?.replaceAll('&gt;', '>');
  }

  addBorderToTables = (doc: any, isCertificate?: boolean) => {
    if (!isCertificate) {
      let all_table = doc.querySelectorAll('table');
      all_table.forEach((e: any) => {
        e.setAttribute('border', '1px');
        e.setAttribute('cellspacing', '0');
        e.setAttribute(
          'style',
          'border:1px solid black;word-break: break-all !important; border-collapse: collapse !important;'
        );
        e.innerHTML = e.innerHTML.trim();
      });

      let all_tr = doc.querySelectorAll('tr');
      all_tr.forEach((e: any) => {
        e.removeAttribute('style');
        e.setAttribute(
          'style',
          'border:1px solid black;word-break: break-all !important; margin: 3px !important'
        );
        e.innerHTML = e.innerHTML.trim();
      });

      let all_th = doc.querySelectorAll('th');
      all_th.forEach((e: any) => {
        e.removeAttribute('style');
        e.setAttribute(
          'style',
          'border:1px solid black;word-break: break-all !important;'
        );
        e.innerHTML = e.innerHTML.trim();
      });
      let all_td = doc.querySelectorAll('td');
      all_td.forEach((e: any) => {
        e.removeAttribute('style');
        e.setAttribute(
          'style',
          'border:1px solid black;word-break: break-all !important;'
        );
        e.innerHTML = e.innerHTML.trim();
      });
    } else {
      let all_th = doc.querySelectorAll('.table-border th');
      all_th.forEach((e: any) => {
        e.removeAttribute('style');
        e.setAttribute(
          'style',
          'border:1px solid black;word-break: break-all !important; padding: 3px !important;'
        );
        e.innerHTML = e.innerHTML.trim();
      });
      let all_td = doc.querySelectorAll('.table-border td');
      all_td.forEach((e: any) => {
        e.removeAttribute('style');
        e.setAttribute(
          'style',
          'border:1px solid black;word-break: break-all !important; padding: 3px !important;'
        );
        e.innerHTML = e.innerHTML.trim();
      });
    }
  };

  getStateName = (states: any[], code: number, key?: string): string =>
    states.find((item) => item.value == code)?.label;

  getDistrictName = (
    districtByState: any[],
    code: number,
    key?: string
  ): string => districtByState.find((item) => item.value == code)?.label;

  chnageKMLplotsArray(data: any) {
    let array: any[] = [];
    data.forEach((ele: any) => {
      array.push({
        'Toposheet No': ele?.toposheet_no,
        'State/UT': ele?.state,
        District: ele?.district,
        'Sub District': ele?.sub_District,
        Village: ele?.village,
        'Plot/Survey/Khasra No.': [ele?.plot_no],
      });
    });
    return array;
  }

  changePartAProductDetails(data: any) {
    let array: any[] = [];
    data.forEach((ele: any) => {
      array.push({
        NameOfProduct: ele?.product_name,
        ProductOrByProduct: ele?.product_by,
        QuantityCapacity: ele?.proposed_quantity,
        Unit: ele?.unit,
        ModeOfTransportTransmission: ele?.transport_mode,
        Remarks: ele?.remarks,
      });
    });
    return array;
  }

  changeConstructionArray(data: any) {
    let array: any[] = [];
    data.forEach((ele: any) => {
      array.push({
        NameOfProduct: ele?.product_name,
        ProductOrByProduct: ele?.product_by,
        QuantityCapacity: ele?.proposed_quantity,
        Unit: ele?.unit,
        ModeOfTransportTransmission: ele?.transport_mode,
        Remarks: ele?.remarks,
      });
    });
    return array;
  }

  changeStramCrossing(data: any) {
    let array: any[] = [];
    data.forEach((ele: any) => {
      array.push({
        temporarily: ele?.temporary_permanent,
        permanently: ele?.temporary_permanent,
        remarks: ele?.remarks,
      });
    });
    return array;
  }

  changePollutionDetailsSource(data: any) {
    let array: any[] = [];
    data.forEach((ele: any) => {
      array.push({
        source: ele?.pollution_source,
        pollutants: ele?.pollutant,
        mitigationMeasures: ele?.mitigation_measures,
      });
    });
    return array;
  }

  changeWasteGenerationType(data: any) {
    let array: any[] = [];
    data.forEach((ele: any) => {
      array.push({
        name: ele?.name,
        source: ele?.source,
        quantityTPA: ele?.quantity,
        modeOfDisposal: ele?.mode_of_disposal,
        modeOfTransport: ele?.mode_of_transport,
      });
    });
    return array;
  }

  chnagePublicHearingDetails(data: any) {
    let array: any[] = [];
    data.forEach((ele: any) => {
      array.push({
        Venue: 'NULL',
        DateOfAdvertisementOfPH: ele?.date_of_advertisment,
        State: ele?.state_name,
        District: ele?.district_name,
        DistanceFromProjectBoundaryInKm: ele?.distance,
        CopyOfProceedings: ele?.public_hearing_copy?.document_name,
        NumberOfPeopleAttended: ele?.no_of_people_attended,
        DesignationOfPresidingOfficer: ele?.designation_of_presiding,
      });
    });
    return array;
  }

  changeMajorIssuesRaised(data: any) {
    let array: any[] = [];
    data.forEach((ele: any) => {
      array.push({
        Issue: ele?.major_issues_raised,
        Response: ele?.pp_response,
        AddressedInFinalEIA_EMP: ele?.is_final_eia_addressed,
        ReferenceOfFinalEIA_EMP: ele?.final_eia_reference,
      });
    });
    return array;
  }

  mapper(data: any, type: string = '') {
    console.log('?????????', data);

    let body: any = {
      CAF: {
        detailsOfProject: {
          'Name of the Project': data?.cafData?.project_name,
          'Project Proposal For': data?.cafData?.proposal_for,
          'Project ID (single Window Number)': data?.cafData?.project_sw_no,
          'Description of Project': data?.cafData?.project_description,
        },
        detailsOfCompany: {
          'Legal Status of the Company/Organization/User Agency':
            data?.cafData?.organization_legal_status,
          'Name of the Company/Organization/User Agency':
            data?.cafData?.organization_name,
        },
        registeredAddress: {
          Address: data?.cafData?.organization_street,
          State: '?? State Code Received from API Asking for name',
          District: '?? State Code Received from API Asking for name',
          'Pin Code': data?.cafData?.organization_pincode,
          'E-mail address': data?.cafData?.organization_email,
          'Mobile number': data?.cafData?.organization_mobile_no,
        },
        applicantDetails: {
          Name: data?.cafData?.applicant_name,
          Designation: data?.cafData?.applicant_designation,
        },
        correspondenceAddress: {
          Address: data?.cafData?.applicant_street,
          State: '?? Id ',
          District: '?? Id',
          'Pin Code': data?.cafData?.applicant_pincode,
          'E-mail address': data?.cafData?.applicant_email,
          'Mobile number': data?.cafData?.applicant_mobile_no,
        },
        projectLocation: {
          'Upload KML': data?.cafData?.cafKML[0]?.caf_kml?.document_name,
          'Whether the project/activity falling in the state/UT sharing international borders':
            data?.cafData?.cafLocationOfKml?.falling_border_in_states,
        },
        locationDetails: this.chnageKMLplotsArray(
          data?.cafData?.cafKML[0]?.cafKMLPlots
        ),
        totalLandArea: {
          Unit: 'Hectares',
          Value: data?.cafData?.cafLocationOfKml?.existing_total_land,
        },
        landRequirement: {
          'Nature of Land involved': null,
          'Non-Forest Land':
            data?.cafData?.cafLocationOfKml?.existing_non_forest_land,
          'Forest Land': data?.cafData?.cafLocationOfKml?.existing_forest_land,
          'Total Land': data?.cafData?.cafLocationOfKml?.existing_total_land,
        },
        projectCost: {
          'Total Cost of the Project at current price level (in Lakhs)':
            data?.cafData?.cafProjectActivityCost?.total_cost,
        },
        employmentGenerated: {
          DuringConstructionPhase: {
            PermanentEmployment: {
              NumberOfPermanentEmployment:
                data?.cafData?.cafProjectActivityCost?.cp_permanent_employment,
              PeriodOfEmploymentInDays:
                data?.cafData?.cafProjectActivityCost?.cp_employment_period,
              NumberOfManDays:
                data?.cafData?.cafProjectActivityCost?.cp_man_of_days,
            },
            TemporaryEmployment: {
              TemporaryContractualEmploymentInManDays:
                data?.cafData?.cafProjectActivityCost?.cp_temp_man_of_days,
              TotalManDays:
                data?.cafData?.cafProjectActivityCost?.cp_total_employment,
            },
          },
          DuringOperationalPhase: {
            PermanentEmployment: {
              NumberOfPermanentEmployment:
                data?.cafData?.cafProjectActivityCost
                  ?.op_total_permanent_employment,
              PeriodOfEmploymentInDays:
                data?.cafData?.cafProjectActivityCost
                  ?.op_total_employment_period,
              NumberOfManDays:
                data?.cafData?.cafProjectActivityCost?.op_total_man_in_days,
            },
            TemporaryEmployment: {
              TemporaryContractualEmploymentInManDays:
                data?.cafData?.cafProjectActivityCost
                  ?.op_total_temp_man_in_days,
              TotalManDays:
                data?.cafData?.cafProjectActivityCost?.op_total_employment,
            },
          },
        },
        rehabilitationAndResettlement: {
          Involved: data?.cafData?.cafOthers?.rnr_involved,
          NumberOfVillages: data?.cafData?.cafOthers?.no_of_villages,
          NumberOfProjectDisplacedFamilies:
            data?.cafData?.cafOthers?.no_of_project_displaced_families,
          NumberOfProjectAffectedFamilies:
            data?.cafData?.no_of_project_affected_families?.rnr_involved,
          StatusOfRehabilitationAndResettlement:
            data?.cafData?.cafOthers?.rnr_status,
        },
        infrastructureShifting: {
          Required: data?.cafData?.cafOthers?.is_required_warehouse_shifting,
          Details: {
            ElectricLine:
              data?.cafData?.cafOthers?.warehouse_shifting_description,
            IrrigationCanal: '?? need to confirm',
          },
        },
        alternativeSiteExamination: {
          Examined: data?.cafData?.cafOthers?.is_alternative_sites_examined,
          Reason: data?.cafData?.cafOthers?.alternative_sites_description,
        },
        governmentOrderOrPolicy: {
          RelevantOrRestricting:
            data?.cafData?.cafOthers?.is_any_govt_restriction,
        },
        litigationPendingAgainstProjectOrLand:
          data?.cafData?.cafOthers?.is_any_litigation_pending,
        proposalInvolvesViolationOfActRuleRegulationNotification:
          data?.cafData?.cafOthers?.is_any_violayion_involved,
      },
      PartA: {
        basicInformation: {
          categoryOfProjectActivity: {
            multipleItemsComponent: data?.ecPartA?.is_multiple_item_envolved,
            majorActivity: {
              itemNumber: '?? We have Id Here',
              activityType: '?? We have Id Here',
              fuelType: '?? We have Id Here',
              capacityMW: '?? We have Id Here',
            },
            minorActivities: [
              {
                itemNumber: '?? We have Id Here',
                activityType: '?? We have Id Here',
                subActivity: '?? We have Id Here',
                capacityMW: 'Capacity value here',
              },
              {
                itemNumber: '?? We have Id Here',
                activityType: '?? We have Id Here',
                subActivity: '?? We have Id Here',
                capacityMW: 'Capacity value here',
              },
            ],
          },
          'Whether project/activity attracts the General Condition specified in the Schedule of EIA Notification?':
            data?.ecPartA?.is_general_condition_specified,
          proximityToProtectedArea: data?.ecPartA?.is_protected,
          proximityToCriticallyPollutedArea:
            data?.ecPartA?.is_critically_polluted,
          proximityToEcoSensitiveArea: data?.ecPartA?.is_eco_sensitive,
          proximityToInterStateAndInternationalBoundaries:
            data?.ecPartA?.is_inter_state,
          proximityToSeverelyPollutedAreas:
            data?.ecPartA?.is_severely_populated,
          categoryOfProject: data?.ecPartA?.project_category,
          interlinkedInterdependentProjectsOrActivities: {
            exists: data?.ecPartA?.is_interlinked_proposal,
            reason: data?.ecPartA?.no_interlinked_proposal_reason,
          },
          forestLandInvolved: data?.ecPartA?.is_fc_involved,
          NBWLRecommendationRequired: data?.ecPartA?.is_nbwl_recomm,
        },
        projectDetails: {
          DetailsOfCTE: {
            ConsentUnderAirAndWaterAct: {
              Obtained:
                data?.ecPartA?.ecProjectDetail?.is_existing_ec_available,
              Reason: data?.ecPartA?.ecProjectDetail?.ec_clearance_reason_type,
            },
          },
          LocatedInNotifiedIndustrialArea:
            data?.ecPartA?.ecProjectDetail?.activity_located_in_sez,
          LocatedInCRZOrICRZArea:
            data?.ecPartA?.ecProjectDetail?.is_activity_located_in_crz,
          ProposedLocationInTerritorialWaters:
            data?.ecPartA?.ecProjectDetail?.is_located_in_territorial_waters,
          AttractsSpecificCondition:
            data?.ecPartA?.ecProjectDetail?.is_specific_condition_specified,
          LocatedInEcoSensitiveZone:
            data?.ecPartA?.ecProjectDetail?.is_located_in_ecosensitive_zone,
        },
        productDetails: {
          DetailsOfProductsAndByProducts: this.changePartAProductDetails(
            data?.ecPartA?.ecProdTransportDetails
          ),
        },
        environmentalSensitiveArea: {
          ExistsWithin10Km:
            data?.ecPartA?.ecProductDetail?.is_envrmnt_sensitive_area_exist,
          Note: '?? Not required',
        },
        baselineDataStatus:
          data?.ecPartA?.ecProductDetail?.baseline_data_status,
        monitoringLocations: {
          Meteorology: data?.ecPartA?.ecProductDetail?.baseline_data_status,
          AmbientAirQuality:
            data?.ecPartA?.ecProductDetail?.nml_ambient_air_quality,
          SurfaceWaterQuality:
            data?.ecPartA?.ecProductDetail?.nml_surface_water_quality,
          GroundWaterQuality:
            data?.ecPartA?.ecProductDetail?.nml_ground_water_quality,
          GroundWaterLevel:
            data?.ecPartA?.ecProductDetail?.nml_phreatic_surface,
          NoiseLevel: data?.ecPartA?.ecProductDetail?.nml_noise_level,
          SoilQuality: data?.ecPartA?.ecProductDetail?.nml_soil_quality,
        },
        additionalDocuments: {
          BriefSummary: 'baseline monitoring.pdf',
          MapOfMonitoringLocations: 'baseline monitoring.pdf',
        },
        consultantDetails: {
          QCI_NABETAccredited: null,
          AccreditationNo_OrganizationId: null,
          NameOfEIAConsultantOrganization: null,
          Address: null,
          MobileNo: null,
          EmailId: null,
          CategoryOfAccreditation: null,
          SectorsOfAccreditation: null,
          ValidityOfAccreditation: null,
        },
      },
      PartB: {
        projectDetails: {
          introductionOfProjectOrActivity: {
            needForProjectOrActivity: data?.ecPartB?.need_of_project,
          },
          socialInfrastructure: {
            existingFacilities: data?.ecPartB?.social_infra_available,
            readilyAvailable: data?.ecPartB?.social_infra_available,
            proposedToBeDeveloped: data?.ecPartB?.social_infra_proposed,
          },
          benefitsOfProject: {
            socialBenefits: data?.ecPartB?.project_social_benefit,
            jobOpportunityToLocalPeople:
              data?.ecPartB?.project_financial_benefit,
          },
          connectivityToProjectOrActivity: {
            nearestRailwayStation: {
              name: data?.ecPartB?.connectivity_railway,
              distanceInKm: data?.ecPartB?.connectivity_railway_distance,
            },
            nearestAirport: {
              name: data?.ecPartB?.connectivity_airport,
              distanceInKm: data?.ecPartB?.connectivity_airport_distance,
            },
            nearestTownCityDistrictHeadQuarter: {
              name: data?.ecPartB?.nearest_town_city_details,
              distanceInKm: data?.ecPartB?.nearest_town_city_details_distance,
            },
          },
          soilClassification: data?.ecPartB?.soil_classification,
          distanceFromHFLOfRiverInMeters: data?.ecPartB?.distance_HFL_river,
          projectActivityConstructionStatus: {
            status: data?.ecPartB?.project_construction_status,
            likelyStartDateOfConstructionActivity:
              data?.ecPartB?.date_of_start_construction,
            likelyDateOfCompletionOfConstructionActivity:
              data?.ecPartB?.date_of_completion_construction,
          },
        },
        constructionDetails: {
          useOfResources: {
            waterRequirement: {
              constructionStage: {
                source: data?.ecPartB?.ecResource[0]?.source,
                quantityInKLD: data?.ecPartB?.ecResource[0]?.quantity_present,
                quantityInKLDWithExpansion:
                  data?.ecPartB?.ecResource[0]?.quantity_expansion,
                methodOfWaterWithdrawal:
                  data?.ecPartB?.ecResource[0]?.method_of_withdrawl,
                distanceFromSourceInMeters:
                  data?.ecPartB?.ecResource[0]?.distance_from_source,
                modeOfTransport: data?.ecPartB?.ecResource[0]?.transport_mode,
                detailsOfPermission:
                  data?.ecPartB?.ecResource[0]?.permission_agreement_details,
              },
              operationalStage: {
                source: data?.ecPartB?.ecResource[1]?.source,
                quantityInKLD: data?.ecPartB?.ecResource[1]?.quantity_present,
                quantityInKLDWithExpansion:
                  data?.ecPartB?.ecResource[1]?.quantity_expansion,
                methodOfWaterWithdrawal:
                  data?.ecPartB?.ecResource[1]?.method_of_withdrawl,
                distanceFromSourceInMeters:
                  data?.ecPartB?.ecResource[1]?.distance_from_source,
                modeOfTransport: data?.ecPartB?.ecResource[1]?.transport_mode,
                detailsOfPermission:
                  data?.ecPartB?.ecResource[1]?.permission_agreement_details,
              },
            },
            requirementOfMineralsAndFuels: {
              coal: {
                quantityPerAnnumInMT:
                  data?.ecPartB?.ecResource[2]?.quantity_present,
                source: data?.ecPartB?.ecResource[2]?.source,
                modeOfTransport: data?.ecPartB?.ecResource[2]?.transport_mode,
                distanceFromSourceInKm:
                  data?.ecPartB?.ecResource[2]?.distance_from_source,
                detailsOfLinkageSupplyAgreement:
                  data?.ecPartB?.ecResource[2]?.permission_agreement_details,
              },
            },
            constructionMaterial: [
              {
                material: data?.ecPartB?.ecResource[3]?.resource_name,
                quantityInMT: data?.ecPartB?.ecResource[3]?.quantity_present,
                source: data?.ecPartB?.ecResource[3]?.source,
                modeOfTransport: data?.ecPartB?.ecResource[3]?.transport_mode,
                distanceFromSourceInKm:
                  data?.ecPartB?.ecResource[3]?.distance_from_source,
              },
            ],
            timber: data?.ecPartB?.ecConstructionDetail?.timber,
            electricPower: {
              totalElectricityRequirementMW:
                data?.ecPartB?.ecConstructionDetail
                  ?.total_electricity_requirment,
              mainSource:
                data?.ecPartB?.ecConstructionDetail?.electric_main_source,
              renewableEnergyProposedToInstallKW:
                data?.ecPartB?.ecConstructionDetail?.electric_renewable_energy,
              percentageContributionOfRenewableEnergy:
                data?.ecPartB?.ecConstructionDetail
                  ?.electric_percentage_contribution,
              standbyArrangements:
                data?.ecPartB?.ecConstructionDetail
                  ?.electric_standby_arrangement,
              stackHeightInMeters:
                data?.ecPartB?.ecConstructionDetail?.electric_stack_height,
              energyConservationMeasures:
                data?.ecPartB?.ecConstructionDetail
                  ?.electric_energy_conservation,
            },
            otherNaturalResources:
              data?.ecPartB?.ecConstructionDetail
                ?.natural_resources_raw_materials,
            useOfHazardousSubstances: null,
            resourceEfficiencyOptimizationRecyclingReuse: {
              envisaged:
                data?.ecPartB?.ecConstructionDetail?.is_resource_efficiency,
              details:
                data?.ecPartB?.ecConstructionDetail
                  ?.resource_efficiency_optimization,
            },
          },
        },
        physicalChanges: {
          landUseChange: {
            currentLandUse: [
              {
                type: 'Forest Land',
                areaInHa: data?.ecPartB?.ecPhysicalChanges?.forest_land_area,
                remarks: data?.ecPartB?.ecPhysicalChanges?.forest_land_remarks,
              },
              {
                type: 'Agriculture land',
                areaInHa:
                  data?.ecPartB?.ecPhysicalChanges?.agriculture_land_area,
                remarks:
                  data?.ecPartB?.ecPhysicalChanges?.agriculture_land_remarks,
              },
              {
                type: 'Grazing Land',
                areaInHa: data?.ecPartB?.ecPhysicalChanges?.grazing_land_area,
                remarks: data?.ecPartB?.ecPhysicalChanges?.grazing_land_remarks,
              },
              {
                type: 'Barren Land',
                areaInHa: data?.ecPartB?.ecPhysicalChanges?.barren_land_area,
                remarks: data?.ecPartB?.ecPhysicalChanges?.barren_land_remarks,
              },
              {
                type: 'Waste Land',
                areaInHa: data?.ecPartB?.ecPhysicalChanges?.waste_land_area,
                remarks: data?.ecPartB?.ecPhysicalChanges?.waste_land_remarks,
              },
              {
                type: 'Surface water bodies',
                areaInHa:
                  data?.ecPartB?.ecPhysicalChanges?.surface_water_bodies_area,
                remarks:
                  data?.ecPartB?.ecPhysicalChanges
                    ?.surface_water_bodies_remarks,
              },
              {
                type: 'Marshy land',
                areaInHa: data?.ecPartB?.ecPhysicalChanges?.marshy_land_area,
                remarks: data?.ecPartB?.ecPhysicalChanges?.marshy_land_remarks,
              },
              {
                type: 'Mangroves',
                areaInHa: data?.ecPartB?.ecPhysicalChanges?.mangroves_area,
                remarks: data?.ecPartB?.ecPhysicalChanges?.mangroves_remarks,
              },
              {
                type: 'Settlements',
                areaInHa: data?.ecPartB?.ecPhysicalChanges?.settlements_area,
                remarks: data?.ecPartB?.ecPhysicalChanges?.settlements_remarks,
              },
              {
                type: 'Roads / Other infrastructure',
                areaInHa: data?.ecPartB?.ecPhysicalChanges?.infrastructure_area,
                remarks:
                  data?.ecPartB?.ecPhysicalChanges?.infrastructure_remarks,
              },
              {
                type: 'Plantation / Green belt',
                areaInHa: data?.ecPartB?.ecPhysicalChanges?.plantation_area,
                remarks: data?.ecPartB?.ecPhysicalChanges?.plantation_remarks,
              },
              {
                type: 'Industrial use',
                areaInHa: data?.ecPartB?.ecPhysicalChanges?.industrial_use_area,
                remarks:
                  data?.ecPartB?.ecPhysicalChanges?.industrial_use_remarks,
              },
              { type: 'Other land use', areaInHa: null, remarks: null },
            ],
            totalAreaInHa:
              data?.ecPartB?.ecPhysicalChanges?.total_current_land_use_area,
            proposedPostProjectLandUse: [
              {
                type: 'Ash Pond',
                areaInHa: 55.03,
                remarks: null,
              },
              {
                type: 'Township',
                areaInHa: 17.8,
                remarks: null,
              },
              {
                type: 'Main Plant',
                areaInHa: 266.11,
                remarks: 'Plant Area with green belt',
              },
              {
                type: 'Miscellaneous',
                areaInHa: 2.66,
                remarks: 'Miscellaneous',
              },
              {
                type: 'Green belt',
                areaInHa: 168.3,
                remarks: 'n/a',
              },
            ],
            totalProposedAreaInHa:
              data?.ecPartB?.ecPhysicalChanges?.total_proposed_land_use,
          },
          vegetationClearance:
            data?.ecPartB?.ecPhysicalChanges?.existing_vegetation_clearance,
          lossOfNativeSpecies:
            data?.ecPartB?.ecPhysicalChanges?.loss_of_native_spieces,
          demolitionWorks:
            data?.ecPartB?.ecPhysicalChanges?.demolition_work_involved,
          linearStructuresDiversion:
            data?.ecPartB?.ecPhysicalChanges
              ?.linear_structure_proposed_for_diversion,
          transportRouteInfrastructureChanges:
            data?.ecPartB?.ecPhysicalChanges?.existing_transportation_change,
          waterBodiesDiversion:
            data?.ecPartB?.ecPhysicalChanges?.water_bodies_change,
          dismantlingDecommissioningRestoration:
            data?.ecPartB?.ecPhysicalChanges?.dismantling_or_restoration_works,
          temporaryConstructionWorks:
            data?.ecPartB?.ecPhysicalChanges?.temporary_use_construction_work,
          cutAndFillExcavations:
            data?.ecPartB?.ecPhysicalChanges?.cut_and_fill_excavations,
          undergroundWorks: data?.ecPartB?.ecPhysicalChanges?.underground_works,
          dredging: data?.ecPartB?.ecPhysicalChanges?.dredging_involved,
          offshoreStructures:
            data?.ecPartB?.ecPhysicalChanges?.offshore_structures_involved,
          newTransportInfrastructure: {
            involved: data?.ecPartB?.ecPhysicalChanges?.new_infrastructure,
            newRoadKm: data?.ecPartB?.ecPhysicalChanges?.new_road_length,
            newRailKm: data?.ecPartB?.ecPhysicalChanges?.new_rail_length,
            jettyLengthInMeters:
              data?.ecPartB?.ecPhysicalChanges?.new_jetty_length,
            airportsHelipad: data?.ecPartB?.ecPhysicalChanges?.airport_details,
            otherTransportFacility:
              data?.ecPartB?.ecPhysicalChanges?.othr_trsprt_fclty,
          },
          constructionOfNewLinearStructures: {
            involved: data?.ecPartB?.ecPhysicalChanges?.new_linear_structure,
            transmissionLineDivertedKm:
              data?.ecPartB?.ecPhysicalChanges
                ?.diverted_transmission_line_length,
            pipelineDivertedKm:
              data?.ecPartB?.ecPhysicalChanges?.diverted_pipeline_length,
            newTransmissionLineKm:
              data?.ecPartB?.ecPhysicalChanges
                ?.proposed_transmission_line_length,
            newPipelineKm:
              data?.ecPartB?.ecPhysicalChanges?.proposed_pipeline_length,
            remarks: data?.ecPartB?.ecPhysicalChanges?.linear_other_info,
          },
          storageFacilities: {
            coveredAreaForStorageSqm:
              data?.ecPartB?.ecPhysicalChanges?.covered_area_proposed,
            openAreaForStorageSqm:
              data?.ecPartB?.ecPhysicalChanges?.open_area_proposed,
            remarks: data?.ecPartB?.ecPhysicalChanges?.goods_storage_othr_info,
          },
          permanentHousingForOperationalWorkers: {
            housingUnits: data?.ecPartB?.ecPhysicalChanges?.no_of_housing_units,
            totalBuiltUpAreaInSqM:
              data?.ecPartB?.ecPhysicalChanges?.housing_built_up_area,
            distanceFromProjectSiteInKm:
              data?.ecPartB?.ecPhysicalChanges?.distance_from_project,
            transportFacilities:
              data?.ecPartB?.ecPhysicalChanges?.transport_facilities,
            remarks:
              data?.ecPartB?.ecPhysicalChanges?.housing_of_workers_other_info,
          },
          hydrologyChanges:
            data?.ecPartB?.ecPhysicalChanges?.changes_to_aquifers,
          streamCrossings: data?.ecPartB?.ecPhysicalChanges?.stream_change,
          influxOfPeople: this.changeStramCrossing(
            data?.ecPartB?.ecStreamCrossing
          ),
          otherInformation: '?? Other information',
        },
        pollutionDetails: {
          airPollution: {
            probableAirPollutantsGenerated:
              data?.ecPartB?.ecPollutionDetails?.air_pollutants_and_Mitigation,
            sources: this.changePollutionDetailsSource(
              data?.ecPartB?.ecAirPollutionMitigations
            ),
          },
          noiseVibration: {
            generation:
              data?.ecPartB?.ecPollutionDetails?.probable_generation_of_noise,
            sourcesOfNoise: data?.ecPartB?.ecPollutionDetails?.source_of_noise,
            sourcesOfVibration:
              data?.ecPartB?.ecPollutionDetails?.source_of_vibration,
            mitigationMeasures: {
              noise:
                data?.ecPartB?.ecPollutionDetails
                  ?.mitigation_measure_for_noise_control,
              vibration:
                data?.ecPartB?.ecPollutionDetails
                  ?.mitigation_measure_for_vibration_control,
            },
          },
          lightHeat: {
            generation:
              data?.ecPartB?.ecPollutionDetails?.probable_generation_of_heat,
            sourcesOfLight: data?.ecPartB?.ecPollutionDetails?.source_of_light,
            sourcesOfHeat: data?.ecPartB?.ecPollutionDetails?.source_of_heat,
            mitigationMeasures: {
              light:
                data?.ecPartB?.ecPollutionDetails
                  ?.mitigation_measure_for_light_control,
              heat: data?.ecPartB?.ecPollutionDetails
                ?.mitigation_measure_for_heat_control,
            },
          },
          waterPollution: {
            probableWaterPollutantsGenerated:
              data?.ecPartB?.ecPollutionDetails?.probable_water_pollutants,
            pollutants: {
              organic: data?.ecPartB?.ecPollutionDetails?.organic_pollutants,
              inorganic:
                data?.ecPartB?.ecPollutionDetails?.inorganic_pollutants,
              heavyMetals: data?.ecPartB?.ecPollutionDetails?.heavy_metals,
            },
            sourcesOfWaterPollution:
              data?.ecPartB?.ecPollutionDetails?.sources_of_water_pollution,
            reuseRecycleOfWastewater: {
              wasteWaterGenerationPerDayKLD:
                data?.ecPartB?.ecPollutionDetails?.recycle_of_waste_water
                  ?.new_quantity_of_wastewater,
              treatedWaterUsePerDayKLD:
                data?.ecPartB?.ecPollutionDetails?.recycle_of_waste_water
                  ?.new_purpose_of_treated_water,
              treatedWaterDischargeOutsidePremisesKLD:
                data?.ecPartB?.ecPollutionDetails?.recycle_of_waste_water
                  ?.new_quantity_of_treated_water,
              purposeForTreatedWaterUse:
                data?.ecPartB?.ecPollutionDetails?.recycle_of_waste_water
                  ?.new_purpose_of_treated_water,
            },
            treatmentFacilities: {
              onSiteSTPETP:
                data?.ecPartB?.ecPollutionDetails?.offsite_treatment_plant,
              wasteWaterTreatment:
                data?.ecPartB?.ecPollutionDetails?.agency_name,
              typeOfTreatmentPlant:
                data?.ecPartB?.ecPollutionDetails?.type_of_treatment_plant,
              capacity: {
                STP: data?.ecPartB?.ecPollutionDetails?.capacity,
                ETP: data?.ecPartB?.ecPollutionDetails?.capacity_both,
              },
              technology: {
                STP: data?.ecPartB?.ecPollutionDetails?.technology,
                ETP: data?.ecPartB?.ecPollutionDetails?.technology_both,
              },
              adequacyCertified:
                data?.ecPartB?.ecPollutionDetails?.treatment_plant_adequacy,
              reasonsForAdequacy:
                data?.ecPartB?.ecPollutionDetails?.adequacy_details,
            },
            dualPlumbingSystem: {
              proposed: data?.ecPartB?.ecPollutionDetails?.dual_plumbing_system,
              details:
                data?.ecPartB?.ecPollutionDetails?.dual_plumbing_system_details,
            },
            dischargeOfTreatedEffluent:
              data?.ecPartB?.ecPollutionDetails?.proposed_mode_of_discharge,
          },
        },
        waterRequirements: {
          groundWaterIntersection: {
            involved:
              data?.ecPartB?.ecWaterDetails?.is_Ground_Water_Intersection,
            areaCategory:
              data?.ecPartB?.ecWaterDetails
                ?.ground_Water_Availability_Description,
          },
          rainwaterHarvesting: {
            proposed: data?.ecPartB?.ecWaterDetails?.is_Rainwater_Harvesting,
            capacityOfFacilitiesKLD:
              data?.ecPartB?.ecWaterDetails?.rainwater_Capacity,
            descriptionOfFacilities:
              data?.ecPartB?.ecWaterDetails?.rainwater_Description,
            details:
              data?.ecPartB?.ecWaterDetails?.rainwater_Harvesting_Description,
            totalWaterRequirementsMetKLD:
              data?.ecPartB?.ecWaterDetails?.rainwater_Harvesting_Quantity,
            storageCapacityCubicMeters:
              data?.ecPartB?.ecWaterDetails?.rainwater_Harvesting_Capacity,
          },
          otherWaterConservationMeasures: {
            proposed: data?.ecPartB?.ecWaterDetails?.is_Water_Conservation,
            details:
              data?.ecPartB?.ecWaterDetails?.other_Water_Conservation_Details,
          },
          zeroLiquidDischarge: {
            proposed: data?.ecPartB?.ecWaterDetails?.is_ZLD_Achieved,
            details: data?.ecPartB?.ecWaterDetails?.zld_Achieved_Details,
          },
        },
        greenbelt: {
          areaProposedInHa:
            data?.ecPartB?.ecGreenBelt?.area_green_belt_existing,
          widthInMeters: data?.ecPartB?.ecGreenBelt?.width_green_belt_existing,
          percentageOfTotalArea:
            data?.ecPartB?.ecGreenBelt?.percentage_total_area_covered_existing,
          speciesProposedForPlantation:
            data?.ecPartB?.ecGreenBelt
              ?.details_species_proposed_plantation_proposed,
          numberOfTreeSaplingsToBePlanted:
            data?.ecPartB?.ecGreenBelt?.number_tree_saplings_planted_proposed,
          fundsAllocatedForPlantationInLakhs:
            data?.ecPartB?.ecGreenBelt?.funds_allocated_plantation_existing,
        },
        wasteGeneration: {
          solidWaste: {
            generation:
              data?.ecPartB?.ecWasteProduction?.is_solid_waste_present,
            types: this.changeWasteGenerationType(
              data?.ecPartB?.ecWasteDetails
            ),
          },
          plasticWaste: {
            generation: data?.ecPartB?.ecWasteProduction?.is_plastic_waste,
            types: this.changeWasteGenerationType(
              data?.ecPartB?.ecWasteDetails
            ),
          },
          eWaste: {
            generation: data?.ecPartB?.ecWasteProduction?.is_ewaste,
            types: this.changeWasteGenerationType(
              data?.ecPartB?.ecWasteDetails
            ),
          },
          batteryWaste: {
            generation: data?.ecPartB?.ecWasteProduction?.is_battery_present,
            types: this.changeWasteGenerationType(
              data?.ecPartB?.ecWasteDetails
            ),
          },
          bioMedicalWaste: {
            generation:
              data?.ecPartB?.ecWasteProduction?.is_biomedical_waste_present,
          },
          hazardousWaste: {
            generation: data?.ecPartB?.ecWasteProduction?.is_hazardous_present,
            types: this.changeWasteGenerationType(
              data?.ecPartB?.ecWasteDetails
            ),
          },
          constructionDemolitionWaste: {
            generation:
              data?.ecPartB?.ecWasteProduction?.is_construction_present,
            types: this.changeWasteGenerationType(
              data?.ecPartB?.ecWasteDetails
            ),
          },
          otherWastes: {
            generation: data?.ecPartB?.ecWasteProduction?.is_other_present,
          },
          surplusProducts: {
            generation:
              data?.ecPartB?.ecWasteProduction?.is_surplus_product_present,
          },
          wasteMinimizationMeasures: {
            proposed:
              data?.ecPartB?.ecWasteProduction?.is_measures_waste_present,
          },
        },
        riskAssessment: {
          risksFromHazardousSubstances: {
            involved:
              data?.ecPartB?.ecRiskFactor?.hazardous_substances_explosion,
            details:
              data?.ecPartB?.ecRiskFactor
                ?.hazardous_substances_explosion_details,
          },
          risksFromOtherCauses: data?.ecPartB?.ecRiskFactor?.any_other_cause,
          naturalDisasters: {
            couldAffectProject:
              data?.ecPartB?.ecRiskFactor?.project_affected_natural_disaster,
          },
          changesInDiseaseOrVectors:
            data?.ecPartB?.ecRiskFactor?.occurences_change_disease,
          adverseEffectsOnWellbeing:
            data?.ecPartB?.ecRiskFactor?.project_adversely_wellbeing,
          vulnerableGroupsAffected:
            data?.ecPartB?.ecRiskFactor?.vulnerable_group_people,
          riskManagementPlan: {
            proposed: data?.ecPartB?.ecRiskFactor?.risk_management_plan,
            details: data?.ecPartB?.ecRiskFactor?.risk_management_plan_details,
          },
          impactsOnAdjacentFacilities:
            data?.ecPartB?.ecRiskFactor?.impact_proposed_activity,
          consequentialDevelopment: {
            couldLeadToEnvironmentalEffects:
              data?.ecPartB?.ecRiskFactor?.lead_development_facilities,
            details:
              data?.ecPartB?.ecRiskFactor?.lead_development_facilities_details,
          },
          afterUseImpactOnEnvironment:
            data?.ecPartB?.ecRiskFactor?.lead_site_impact,
          precedentForLaterDevelopments: {
            involved: data?.ecPartB?.ecRiskFactor?.precedent_later_development,
            details:
              data?.ecPartB?.ecRiskFactor?.precedent_later_development_details,
          },
          cumulativeEffects:
            data?.ecPartB?.ecRiskFactor?.have_cumulative_effect,
          growthOfAlienSpecies:
            data?.ecPartB?.ecRiskFactor?.lead_growth_species,
          threatToBiodiversity:
            data?.ecPartB?.ecRiskFactor?.any_threat_biodiversity,
          obstructionOfViewScenicAmenity:
            data?.ecPartB?.ecRiskFactor?.will_proposed_project,
          impactOnAnthropologicalArchaeologicalSites:
            data?.ecPartB?.ecRiskFactor?.any_impact_anthropological,
          changesToDemographicStructure:
            data?.ecPartB?.ecRiskFactor?.will_proposal_result,
          adverseEffectOnLocalCommunities:
            data?.ecPartB?.ecRiskFactor?.will_proposal_cause,
        },
        undertaking: {
          statement:
            'I hereby give undertaking that the data and information given in the application and enclosures are true to be best of my knowledge and belief and I am aware that if any part of the data and information is found to be false or misleading at any stage, the project will be rejected and clearance given if any to the project will be revoked at our risk and cost. In addition to the above, I hereby give undertaking that no activity/construction/expansion has been taken up.',
          name: data?.ecPartA?.ecOthersDetail?.undertaking_person_name,
          designation:
            data?.ecPartA?.ecOthersDetail?.undertaking_person_designation,
          company: data?.ecPartA?.ecOthersDetail?.undertaking_person_company,
          address: data?.ecPartA?.ecOthersDetail?.undertaking_person_address,
          date: data?.ecPartA?.ecOthersDetail?.undertaking_date,
        },
      },
    };

    if (type == 'PartC') {
      body['PartC'] = {
        DetailsOfTermsOfReference: {
          NatureOfTheToR: data?.ecPartC?.nature_of_tor,
          DateOfIssuanceOfToR: data?.ecPartC?.date_of_issue_tor,
          DateOfIssuanceOfAdditionalToR:
            data?.ecPartC?.date_of_issue_additional_tor,
          MoEFAndCC_SEIAAFileNo: data?.ecPartC?.moef_file_no,
          UploadToRLetter: data?.ecPartC?.tor_letter?.document_name,
          AmendmentToToR: data?.ecPartC?.is_any_amendment_tor,
        },
        DetailsOfPublicConsultation: {
          ExemptedFromPublicHearing: data?.ecPartC?.is_project_exempted,
          PublicHearingDetails: this.chnagePublicHearingDetails(
            data?.ecPartC?.ecPublicHearings
          ),
          NumberOfWrittenCommentsReceived:
            data?.ecPartC?.no_of_written_comments,
          MajorIssuesRaised: this.changeMajorIssuesRaised(
            data?.ecPartC?.ecMajorIssuesRaiseds
          ),
          ActionPlanOnIssuesRaised: 'cer_activity_&_village_development.pdf',
          UploadDocumentsIfAny: 'ph_action_plan.pdf',
        },
        BaselineDetails: {
          SummaryOfBaselineData: {
            Season:
              data?.ecPartA?.ecProductDetail?.seasonECA ||
              data?.ecPartA?.ecProductDetail?.seasonECB,
            PeriodOfCollection: {
              From: data?.ecPartC?.ecBaseLineDetails?.period_from,
              To: data?.ecPartC?.ecBaseLineDetails?.period_to,
            },
            NumberOfMonitoringLocations: {
              Meteorology: data?.ecPartC?.ecBaseLineDetails?.meteorology,
              AmbientAirQuality: data?.ecPartC?.ecBaseLineDetails?.ambient_air,
              SurfaceWaterQuality:
                data?.ecPartC?.ecBaseLineDetails?.water_surface,
              GroundWaterQuality:
                data?.ecPartC?.ecBaseLineDetails?.ground_water,
              GroundWaterLevel:
                data?.ecPartC?.ecBaseLineDetails?.ground_water_level,
              NoiseLevel: data?.ecPartC?.ecBaseLineDetails?.noise_level,
              SoilQuality: data?.ecPartC?.ecBaseLineDetails?.soil_quality,
            },
          },
          MeteorologicalParameters: {
            Temperature: {
              MinValue: data?.ecPartC?.ecBaseLineDetails?.temperature_min,
              MaxValue: data?.ecPartC?.ecBaseLineDetails?.temperature_max,
              MeanValue: data?.ecPartC?.ecBaseLineDetails?.temperature_mean,
            },
            WindSpeed: {
              MinValue: data?.ecPartC?.ecBaseLineDetails?.wind_speed_min,
              MaxValue: data?.ecPartC?.ecBaseLineDetails?.wind_speed_max,
              MeanValue: data?.ecPartC?.ecBaseLineDetails?.wind_speed_mean,
            },
            RelativeHumidity: {
              MinValue: data?.ecPartC?.ecBaseLineDetails?.relative_humidity_min,
              MaxValue: data?.ecPartC?.ecBaseLineDetails?.relative_humidity_max,
              MeanValue:
                data?.ecPartC?.ecBaseLineDetails?.relative_humidity_mean,
            },
            SolarRadiation: {
              MinValue: data?.ecPartC?.ecBaseLineDetails?.solar_radiation_min,
              MaxValue: data?.ecPartC?.ecBaseLineDetails?.solar_radiation_max,
              MeanValue: data?.ecPartC?.ecBaseLineDetails?.solar_radiation_mean,
            },
            Rainfall: {
              TotalRainfall: data?.ecPartC?.ecBaseLineDetails?.rainfall_total,
              NumberOfRainyDays:
                data?.ecPartC?.ecBaseLineDetails?.rainfall_days,
              AverageAnnualRainfall:
                data?.ecPartC?.ecBaseLineDetails?.rainfall_average,
            },
            PredominantWindDirection:
              data?.ecPartC?.ecBaseLineDetails?.wind_direction,
          },
          AmbientAirQuality: {
            BufferZone: {
              PM10: {
                From: 44.2,
                To: 67.1,
                MeanValue: 55.65,
                PrescribedStandard: 100,
              },
            },
            CoreZone: {
              PM10: {
                From: 51.6,
                To: 57.6,
                MeanValue: 57.65,
                PrescribedStandard: 100,
              },
            },
          },
          // ... Continue with Surface Water Quality, Ground Water Quality, etc.
        },
        ImpactPrediction: {
          AirQualityImpactPrediction: {
            BufferZone: {
              PM: {
                BaselineConcentration: 54.74,
                PredictedIncrementalValue: 0.6,
                TotalGLC: 55.34,
                PrescribedStandard: 100,
                LatLong: '14° 28\' 14"N 76° 28\' 18"E',
              },
            },
          },
        },
        FundsAllocatedForEnvironmentManagement: {
          Capital: data?.ecPartC?.ecBaseLineDetails?.total_capital_cost,
          CorporateEnvironmentalResponsibility:
            data?.ecPartC?.ecBaseLineDetails?.corporate_environment_funds,
          EMPRecurringPerAnnum:
            data?.ecPartC?.ecBaseLineDetails?.total_recurring_cost,
          SummaryOfAllocationOfFundForEMP: {
            AirEnvironment: data?.ecPartC?.ecSummaryAllocations[0]?.emp,
            WaterEnvironment:
              data?.ecPartC?.ecSummaryAllocations[0]?.capital_cost,
            NoiseEnvironment:
              data?.ecPartC?.ecSummaryAllocations[0]?.recurring_cost,
            Total: data?.ecPartC?.ecSummaryAllocations[0]?.total_capital_cost,
          },
        },
        PostProjectMonitoringProgram: {
          ParametersProposedForMonitoring: {
            AirQuality: {
              Parameters: data?.ecPartC?.ecParameterMonitors[0]?.attribute,
              ModeOfMonitoring:
                data?.ecPartC?.ecParameterMonitors[0]?.proposed_parameter,
              FrequencyOfMonitoring:
                data?.ecPartC?.ecParameterMonitors[0]?.monitoring_frequency,
              ProjectPhase:
                data?.ecPartC?.ecParameterMonitors[0]?.project_phase,
              MonitoringAgency:
                data?.ecPartC?.ecParameterMonitors[0]?.monitoring_agency,
              LatLong: data?.ecPartC?.ecParameterMonitors[0]?.location_long,
            },
          },
        },
        EnvironmentalManagementCell: {
          OrganizationalStructure:
            data?.ecPartC?.ecOtherDetails?.env_organisational_structure,
          DetailsOfResponsibilities:
            data?.ecPartC?.ecOtherDetails?.details_of_responsibilities,
          ProcedureToReportObservation:
            data?.ecPartC?.ecOtherDetails?.procedure_to_report,
        },
        Enclosures: {
          DocumentsToBeAttached: [
            {
              DocumentName: 'Final EIA/EMP Report',
              UploadCopy: 'ground_water_permission.pdf',
            },
            {
              DocumentName:
                'Executive Summary of Feasibility Report/Project Report',
              UploadCopy: 'cer_activity_&_village_development.pdf',
            },
            {
              DocumentName: 'Final Layout Plan',
              UploadCopy: 'sample_(l).pdf',
            },
          ],
        },
        AdditionalInformation: {
          Documents: [
            {
              SerialNumber: 1,
              DocumentName: 'List of Machineries',
              DocumentFile: 'annexure_6_-_gram_panchayat_noc.pdf',
              Remark: 'List of Machineries',
            },
            {
              SerialNumber: 2,
              DocumentName: 'ToR Copy',
              DocumentFile: 'tor_copy.pdf',
              Remark: 'ToR Copy',
            },
            {
              SerialNumber: 3,
              DocumentName: 'Land Documents',
              DocumentFile: 'ground_water_permission.pdf',
              Remark: 'Land Documents',
            },
          ],
        },
      };
    }

    return body;
  }
}
